// Imports
import React, { useState } from "react";

// React context
export const myContext = React.createContext();

// ======== Component ========
const ProviderComponent = (props) => {
  
  // This component's state will persist through page changes 
  // and be accessible on every page; it "wraps" the whole app

  // State
  const [count, setCount] = useState(0);

  // Functions
  const increaseCount = () => {
    setCount(count + 1);
  };

  // Pass functions and data into the value object of myContext.Provider.
  return (
    <myContext.Provider value={{ 
      count: count, 
      "increaseCount": increaseCount, 
    }}>
      {props.children}
    </myContext.Provider>
  );
};

// ======== Component ========
const Provider = ({ element }) => {
  return (
    <ProviderComponent>
      {element}
    </ProviderComponent>
  );
};

export default Provider;